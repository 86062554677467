import {
  Container,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";

// import FormPanel from "./FormPanel";
import MethodsPanel from "./MethodsPanel";

function Contact() {
  return (
    <Container marginY={{ base: 5, md: 10 }}>
      <Stack spacing={5}>
        <Heading>联系我们</Heading>
        <Tabs>
          <TabList>
            <Tab>联系方式</Tab>
            <Tab isDisabled>填写表单</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <MethodsPanel />
            </TabPanel>
            <TabPanel>{/* <FormPanel /> */}</TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </Container>
  );
}

export default Contact;
