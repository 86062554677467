import { PhoneIcon } from "@chakra-ui/icons";
import {
  HStack,
  IconButton,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";

function MethodsPanel() {
  return (
    <Stack spacing={5}>
      <HStack>
        <Stat>
          <StatLabel>公司电话号码</StatLabel>
          <StatNumber>+86 (752) 892 2189</StatNumber>
        </Stat>
        <IconButton
          aria-label="拨打公司电话。"
          as="a"
          href="tel:+867528922189"
          icon={<PhoneIcon />}
        ></IconButton>
      </HStack>
      <Stat>
        <StatLabel>公司传真</StatLabel>
        <StatNumber>+86 (752) 892 2186</StatNumber>
      </Stat>
      <Stat>
        <StatLabel>公司地址</StatLabel>
        <StatNumber>中国 广东省 惠东县 平山镇 民营科技工业园区</StatNumber>
      </Stat>
      <Stat>
        <StatLabel>代表人电子邮箱地址</StatLabel>
        <StatNumber>cheung8688@gmail.com</StatNumber>
      </Stat>
    </Stack>
  );
}

export default MethodsPanel;
