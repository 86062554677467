import { Link as RouterLink } from "react-router-dom";

import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Icon,
  Link,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import NavItem from "./NavItem";

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
  return (
    <RouterLink to={href ?? "#"}>
      <Link
        _hover={{ bg: useColorModeValue("pink.50", "gray.900") }}
        borderRadius={"md"}
        display={"block"}
        p={2}
        role={"group"}
      >
        <Stack alignItems={"center"} direction={"row"}>
          <Box>
            <Text
              _groupHover={{ color: "pink.400" }}
              fontWeight={500}
              transition={"all .3s ease"}
            >
              {label}
            </Text>
            <Text fontSize={"sm"}>{subLabel}</Text>
          </Box>
          <Flex
            _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
            alignItems={"center"}
            flex={1}
            justifyContent={"flex-end"}
            opacity={0}
            transform={"translateX(-10px)"}
            transition={"all .3s ease"}
          >
            <Icon
              as={ChevronRightIcon}
              color={"pink.400"}
              height={5}
              width={5}
            />
          </Flex>
        </Stack>
      </Link>
    </RouterLink>
  );
};

export default DesktopSubNav;
