import { useState } from "react";

import {
  Container,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";

import Album from "./Album";

function Gallery() {
  const [tabIndex, setTabIndex] = useState(1);

  return (
    <Container marginY={{ base: 5, md: 10 }} maxWidth="container.lg">
      <Stack spacing={5}>
        <Heading>图库</Heading>
        <Text>
          {tabIndex === 0
            ? "图片仅供参考。"
            : "图片仅供参考，请以实际产品为准。"}
        </Text>
        <Tabs
          defaultIndex={1}
          onChange={(index) => {
            setTabIndex(index);
          }}
          variant={"solid-rounded"}
        >
          <TabList>
            <Tab>工厂</Tab>
            <Tab>充电宝</Tab>
            <Tab>充电线</Tab>
            <Tab>充电桩</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Album category="factory" images={IMAGES.factory} />
            </TabPanel>
            <TabPanel>
              <Album category="power_bank" images={IMAGES.power_bank} />
            </TabPanel>
            <TabPanel>
              <Album category="charging_cable" images={IMAGES.charging_cable} />
            </TabPanel>
            <TabPanel>
              <Album category="charging_pile" images={IMAGES.charging_pile} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </Container>
  );
}

export default Gallery;

const getIndexArray = (total: number) => {
  return Array.from(Array(total).keys()).map(
    (index) => index.toString() + ".jpg"
  );
};

const IMAGES = {
  factory: ["front.jpg", "over.jpg"],
  power_bank: getIndexArray(36),
  charging_cable: getIndexArray(17),
  charging_pile: getIndexArray(3),
};
