import { Link } from "react-router-dom";

import { Button, ButtonGroup } from "@chakra-ui/react";

function Actions() {
  return (
    <ButtonGroup size={"lg"} spacing={{ base: 4, sm: 6 }}>
      <Link to="contact">
        <Button
          _hover={{ bg: "cyan.600" }}
          background={"cyan.500"}
          colorScheme={"teal"}
          fontWeight={"normal"}
        >
          联系我们
        </Button>
      </Link>
      <Link to="gallery">
        <Button fontWeight={"normal"}>产品图库</Button>
      </Link>
    </ButtonGroup>
  );
}

export default Actions;
