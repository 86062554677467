import { Box, Flex, Image } from "@chakra-ui/react";

import ImageFactoryFront from "../../../assets/images/factory/front.jpg";

function Graphic() {
  return (
    <Flex flex={1} position={"relative"} w={"full"}>
      <Box
        borderRadius={"2xl"}
        boxShadow={"2xl"}
        height={"300px"}
        overflow={"hidden"}
        position={"relative"}
        width={"full"}
      >
        <Image
          alignItems={"center"}
          alt={"Hero Image"}
          height={"100%"}
          objectFit={"cover"}
          src={ImageFactoryFront}
          width={"100%"}
        />
      </Box>
    </Flex>
  );
}

export default Graphic;
