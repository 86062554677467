import { Heading, Stack, Text } from "@chakra-ui/react";

import Actions from "./Actions";

function Content() {
  return (
    <Stack flex={1} spacing={{ base: 5, md: 10 }}>
      <Heading
        fontSize={{ base: "4xl", lg: "6xl" }}
        fontWeight={600}
        lineHeight={1.1}
      >
        <Text as={"span"}>我们是，</Text>
        <br />
        <Text as={"span"} color={"cyan.500"}>
          富达电子！
        </Text>
      </Heading>
      <Text color={"gray.500"}>
        惠州富达电子有限公司，成立于 2004 年，位于 广东省 惠州市 惠东县 平山镇
        民营科技工业园区，是一家由香港 富泰国际电子有限公司
        投资的公司。厂房自建面积 2
        万平方米，从事研发、生产、销售电子科技。主要生产的产品包括充电宝、充电线和新能源汽车充电桩。公司本着“高效、诚信、合作共赢”的理念，以客户需求为导向，积极创新；凭借着高质量的产品、良好的信誉、优质的服务，产品畅销国内外巿场。我们竭诚与国内外商家双赢合作，共同发展，共创辉煌！
      </Text>
      <Actions />
    </Stack>
  );
}

export default Content;
