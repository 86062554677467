import { type FC, type ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";

import {
  Box,
  Link,
  Popover,
  PopoverContent,
  PopoverTrigger as OriginalPopoverTrigger,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";

import DesktopSubNav from "./DesktopSubNav";
import NavItem from "./NavItem";

const PopoverTrigger: FC<{ children: ReactNode }> = OriginalPopoverTrigger;

function DesktopNav(props: { items: NavItem[] }) {
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  return (
    <Stack direction={"row"} spacing={4}>
      {props.items.map((navItem) => (
        <Box key={navItem.label}>
          <Popover placement={"bottom-start"} trigger={"hover"}>
            <PopoverTrigger>
              <RouterLink to={navItem.href ?? "#"}>
                <Link
                  _hover={{
                    textDecoration: "none",
                    color: linkHoverColor,
                  }}
                  color={linkColor}
                  fontSize={"sm"}
                  fontWeight={500}
                  p={2}
                >
                  {navItem.label}
                </Link>
              </RouterLink>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                background={popoverContentBgColor}
                border={0}
                borderRadius={"xl"}
                boxShadow={"xl"}
                minWidth={"sm"}
                p={4}
              >
                <Stack>
                  {navItem.children.map(
                    (child: JSX.IntrinsicAttributes & NavItem) => (
                      <DesktopSubNav key={child.label} {...child} />
                    )
                  )}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
}

export default DesktopNav;
