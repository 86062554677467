import { Route, Routes } from "react-router-dom";

import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Contact from "./pages/Contact";
import Gallery from "./pages/Gallery";
import Homepage from "./pages/Homepage";
import Legal from "./pages/Legal";

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route element={<Homepage />} path="/" />
        <Route element={<Gallery />} path="gallery" />
        <Route element={<Contact />} path="contact" />
        <Route element={<Legal />} path="legal" />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
