import { Container, Stack } from "@chakra-ui/react";

import Content from "./Content";
import Graphic from "./Graphic";

function Hero() {
  return (
    <Container maxWidth={"7xl"} padding={{ base: 5, sm: 10, md: 20 }}>
      <Stack
        alignItems={"center"}
        direction={{ base: "column", md: "row" }}
        paddingY={{ base: 15, md: 25 }}
        spacing={{ base: 8, md: 10 }}
      >
        <Content />
        <Graphic />
      </Stack>
    </Container>
  );
}

export default Hero;
