import { Link } from "react-router-dom";

import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Collapse,
  Flex,
  IconButton,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";

import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";
import NavItem from "./NavItem";

function Navbar() {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box>
      <Flex
        alignItems={"center"}
        background={useColorModeValue("white", "gray.800")}
        borderBottom={1}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        borderStyle={"solid"}
        color={useColorModeValue("gray.600", "white")}
        minHeight={"60px"}
        paddingX={{ base: 4 }}
        paddingY={{ base: 2 }}
      >
        <Flex
          display={{ base: "flex", md: "none" }}
          flex={{ base: 1, md: "auto" }}
          marginLeft={{ base: -2 }}
        >
          <IconButton
            aria-label={"Toggle Navigation"}
            icon={
              isOpen ? (
                <CloseIcon height={3} width={3} />
              ) : (
                <HamburgerIcon height={5} width={5} />
              )
            }
            onClick={onToggle}
            variant={"ghost"}
          />
        </Flex>
        <Flex
          flex={{ base: 1 }}
          justifyContent={{ base: "center", md: "start" }}
        >
          <Text
            color={useColorModeValue("gray.800", "white")}
            fontFamily={"heading"}
            textAlign={useBreakpointValue({ base: "center", md: "left" })}
          >
            富达电子
          </Text>

          <Flex display={{ base: "none", md: "flex" }} ml={10}>
            <DesktopNav items={NAV_ITEMS} />
          </Flex>
        </Flex>

        <Stack
          direction={"row"}
          flex={{ base: 1, md: 0 }}
          justifyContent={"flex-end"}
          spacing={6}
        >
          <Link to="contact">
            <Button
              _hover={{
                bg: "pink.300",
              }}
              background={"pink.400"}
              color={"white"}
              display={{ base: "none", md: "inline-flex" }}
              fontSize={"sm"}
              fontWeight={600}
            >
              联系我们
            </Button>
          </Link>
        </Stack>
      </Flex>

      <Collapse animateOpacity in={isOpen}>
        <MobileNav items={NAV_ITEMS} />
      </Collapse>
    </Box>
  );
}

export default Navbar;

const NAV_ITEMS: NavItem[] = [
  {
    label: "主页",
    href: "/",
  },
  {
    label: "图库",
    href: "gallery",
  },
  {
    label: "法律信息",
    href: "legal",
  },
];
