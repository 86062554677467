import { Grid, Image } from "@chakra-ui/react";

function Album(props: { category: string; images: string[] }) {
  return (
    <Grid
      gridGap={5}
      templateColumns={{
        base: "repeat(2, 1fr)",
        sm: "repeat(3, 1fr)",
        md: "repeat(4, 1fr)",
        lg: "repeat(5, 1fr)",
      }}
    >
      {props.images.map((image) => {
        return (
          <Image
            alt={props.category}
            borderRadius="2xl"
            key={image}
            src={require(`../../assets/images/${props.category}/${image}`)}
          ></Image>
        );
      })}
    </Grid>
  );
}

export default Album;
