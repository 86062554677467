import { Container, Grid, Heading, Image, Stack } from "@chakra-ui/react";

function Legal() {
  return (
    <Container marginY={{ base: 5, md: 10 }}>
      <Stack spacing={5}>
        <Heading>法律信息</Heading>
        <Grid
          gridGap={5}
          templateColumns={{
            base: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
          }}
        >
          {IMAGES.map((image) => {
            return (
              <Image
                alt={image}
                borderRadius="2xl"
                boxShadow={"xl"}
                key={image}
                src={require(`../../assets/images/legal/${image}`)}
              ></Image>
            );
          })}
        </Grid>
      </Stack>
    </Container>
  );
}

export default Legal;

const IMAGES = ["business_license.jpg", "foreign_investment.jpg"];
