import {
  Box,
  Container,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

function Footer() {
  return (
    <Box
      background={useColorModeValue("gray.100", "gray.900")}
      color={"gray.500"}
    >
      <Container
        alignItems={"center"}
        as={Stack}
        direction={{ base: "column", md: "row" }}
        maxWidth={"6xl"}
        paddingY={4}
      >
        <Text>版权所有 © 2015-2022 惠州富达电子有限公司。保留所有权利。</Text>
      </Container>
    </Box>
  );
}

export default Footer;
